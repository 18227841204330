import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  SERVICE_CATEGORIES,
  submitJobForReview,
  getIsSubmittingJobForReview,
  getJobDetails,
} from 'modules/jobDetails'
import { UploadPanel } from './UploadPanel'
import { Button } from '../common/Button'
import { useHistory } from 'react-router-dom'
import { formatDate } from 'utils/formatDate'

function UploadPage() {
  const history = useHistory()

  const isSubmittingJob = useSelector(getIsSubmittingJobForReview)
  const {
    reference,
    status: jobStatus,
    title,
    description,
    startedAt,
    finishedAt,
    uploadCode,
    project,
    product,
  } = useSelector(getJobDetails)

  const [termsOfUseAccepted, setTermsOfUseAccepted] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const fileUploaderRef = React.useRef(null)

  useEffect(() => {
    setErrorMessage()
  }, [termsOfUseAccepted])

  const dispatch = useDispatch()

  function dispatchSubmitJobForReview() {
    if (!termsOfUseAccepted) {
      setErrorMessage('You must accept the terms of use before submitting')
    } else if (fileUploaderRef.current?.getFiles().length === 0) {
      setErrorMessage('At least 1 file must be uploaded before submitting')
    } else {
      dispatch(submitJobForReview.request(reference, uploadCode))
    }
  }

  return (
    <div className="h-full py-8 flex flex-col items-center justify-center overflow-y-scroll">
      <img
        className="mb-8 mt-8"
        style={{ height: 80, width: 120 }}
        src="/ghd-logo.jpg"
      />

      <div>
        <div
          className="bg-white rounded-lg shadow-lg p-8 flex flex-col"
          style={{
            minWidth: 800,
          }}
        >
          <div className="mb-8 relative">
            <h1 className="mb-2 text-gray-700 text-center">Upload Files</h1>
            <p className="mb-12 text-center text-sm text-gray-500 font-light">
              {jobStatus === 'job_status_finished'
                ? 'Upload everything you need before submitting the job for review.'
                : 'This job is being reviewed. Please upload or re-upload anything as required'}
            </p>

            <div className="flex">
              <dl className="pr-4 w-1/2">
                <dt>Title</dt>
                <dd
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  className="title-container"
                >
                  {title}
                </dd>
                <div
                  className="title-tooltip absolute invisible p-2 bg-white rounded-lg border-solid border-gray-800 top-0 shadow-md"
                  style={{ top: 90 }}
                >
                  <span className="text-gray-700 font-normal">{title}</span>
                </div>

                <dt>Reference No</dt>
                <dd>{reference}</dd>

                <dt>Project ID</dt>
                <dd>{project?.reference ?? 'Not Yet Available'}</dd>

                <dt>Description</dt>
                <dd>{description}</dd>
              </dl>

              <dl className="w-1/2">
                <dt>Service Category</dt>
                <dd>
                  {SERVICE_CATEGORIES[product?.serviceCategory] ?? 'Not Available'}
                </dd>

                <dt>Start Date</dt>
                <dd>{formatDate(startedAt)}</dd>

                <dt>Finish Date</dt>
                <dd>{formatDate(finishedAt)}</dd>
              </dl>
            </div>
            <div
              className="mt-2 cursor-pointer"
              onClick={() => setTermsOfUseAccepted(!termsOfUseAccepted)}
            >
              <div className="flex">
                <div className="mr-2">
                  <input
                    type="checkbox"
                    className="w-5 h-5 cursor-pointer"
                    name="termsOfUseAccepted"
                    aria-label="Accept Terms of Use"
                    checked={termsOfUseAccepted}
                    onChange={() => setTermsOfUseAccepted(!termsOfUseAccepted)}
                  />
                </div>
                <p className="text-gray-700">
                  I agree that any files I upload are subject to{' '}
                  <a
                    className="underline"
                    href="https://platform-assets.nearbysky.com/ghd_tc.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    GHD terms of use.
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              pointerEvents: termsOfUseAccepted ? 'initial' : 'none',
              opacity: termsOfUseAccepted ? 1 : 0.2,
            }}
          >
            <UploadPanel ref={fileUploaderRef} />
          </div>

          <p className="text-red-600 m-0 p-0 mb-4 text-center">{errorMessage}</p>

          {jobStatus === 'job_status_finished' ? (
            <div className="flex justify-between">
              <Button onClick={() => history.replace('/')}>Save &amp; Log Out</Button>
              <Button onClick={dispatchSubmitJobForReview} loading={isSubmittingJob}>
                Submit for review
              </Button>
            </div>
          ) : (
            <div className="flex justify-center mb-2">
              <Button onClick={() => history.replace('/')}>Save &amp; Log Out</Button>
            </div>
          )}
        </div>
      </div>

      <p className="mt-4 text-center text-gray-500 text-xs">
        {' '}
        © {new Date().getFullYear()} NearbySky. All rights reserved.{' '}
      </p>
    </div>
  )
}

export { UploadPage }
