import { composeSelectors } from 'utils/redux'
import { FETCH_JOB_DETAILS, SUBMIT_JOB_FOR_REVIEW } from './actions'
import { createLoadingStateSelector } from 'modules/requestStates'
import { prop } from 'ramda'

const getModuleState = prop('jobDetails')

const getIsLoadingJobDetails = createLoadingStateSelector([FETCH_JOB_DETAILS])
const getIsSubmittingJobForReview = createLoadingStateSelector([SUBMIT_JOB_FOR_REVIEW])

const getJobDetails = composeSelectors(getModuleState, prop('entity'))
const getUploadCode = composeSelectors(getModuleState, prop('uploadCode'))

const getReferenceNumber = composeSelectors(getJobDetails, prop('reference'))

const getSubmittedForReview = composeSelectors(
  getModuleState,
  prop('submittedForReview')
)

export {
  getJobDetails,
  getUploadCode,
  getIsLoadingJobDetails,
  getIsSubmittingJobForReview,
  getReferenceNumber,
  getSubmittedForReview,
}
