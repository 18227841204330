import { combineReducers } from 'redux'
import { connectRouter as createRouterReducer } from 'connected-react-router'

import { requestStatesReducer } from './requestStates'
import { MODULE_NAMESPACE as REQUEST_STATES_NAMESPACE } from './requestStates/constants'
import { authReducer } from './auth/reducer'
import { jobDetailsReducer, jobDetailsSagas } from './jobDetails'

function createRootReducer(history) {
  return combineReducers({
    router: createRouterReducer(history),
    [REQUEST_STATES_NAMESPACE]: requestStatesReducer,
    auth: authReducer,
    jobDetails: jobDetailsReducer,
  })
}

const moduleSagas = [...jobDetailsSagas]

export { createRootReducer, moduleSagas }
