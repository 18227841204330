import { takeLatest, call, put } from 'redux-saga/effects'
import {
  FETCH_JOB_DETAILS,
  SUBMIT_JOB_FOR_REVIEW,
  fetchJobDetails,
  submitJobForReview,
} from './actions'
import { push } from 'connected-react-router'
import { Api } from 'api'

function* fetchJobDetailsWatcher() {
  yield takeLatest(FETCH_JOB_DETAILS.REQUEST, fetchJobDetailsSaga)
}

function* fetchJobDetailsSaga(action) {
  const { referenceNo, uploadCode } = action.payload

  try {
    const { jobDetails, bearerToken } = yield call(
      Api.getJobDetails,
      referenceNo,
      uploadCode
    )
    yield put(fetchJobDetails.succeeded(jobDetails, bearerToken))

    if (['job_status_in_review', 'job_status_finished'].includes(jobDetails.status)) {
      yield put(push('/upload'))
    }
  } catch (error) {
    yield put(fetchJobDetails.failed(error.message))
  }
}

function* submitJobForReviewWatcher() {
  yield takeLatest(SUBMIT_JOB_FOR_REVIEW.REQUEST, submitJobForReviewSaga)
}

function* submitJobForReviewSaga(action) {
  const { referenceNo, uploadCode } = action.payload

  try {
    yield call(Api.submitJobForReview, referenceNo, uploadCode)
    yield put(submitJobForReview.succeeded())
    yield put(push('/submitted'))
  } catch (error) {
    yield put(submitJobForReview.failed(error.message))
  }
}

const jobDetailsSagas = [fetchJobDetailsWatcher, submitJobForReviewWatcher]

export { jobDetailsSagas }
