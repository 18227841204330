import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools as withDevTools } from 'redux-devtools-extension/developmentOnly'

import { createRootReducer, moduleSagas } from 'modules'

const sagaMiddleware = createSagaMiddleware()

function configureStore(history) {
  const routerMiddleware = createRouterMiddleware(history)
  const appMiddleware = withDevTools(applyMiddleware(sagaMiddleware, routerMiddleware))
  const rootReducer = createRootReducer(history)

  const store = createStore(rootReducer, {}, appMiddleware)

  moduleSagas.forEach((saga) => sagaMiddleware.run(saga))

  return store
}

export { configureStore }
