import { createReducer } from 'utils/redux'
import { FETCH_JOB_DETAILS, SUBMIT_JOB_FOR_REVIEW } from 'modules/jobDetails'

const getInitialState = () => ({ bearerToken: undefined })

const authReducer = createReducer(getInitialState(), {
  [FETCH_JOB_DETAILS.SUCCESS]: (state, action) => ({
    ...state,
    bearerToken: action.payload.bearerToken,
  }),

  [SUBMIT_JOB_FOR_REVIEW.SUCCESS]: getInitialState,
})

export { authReducer }
