import PropTypes from 'prop-types'

export const Button = ({ children, loading = false, ...props }) => {
  return (
    <button
      className="bg-blue-200 text-gray-800 border-b-2 border-blue-400 uppercase tracking-wide
            hover:text-white hover:bg-blue-401 active:bg-blue-200 text-sm font-medium
            py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
      type="button"
      {...props}
    >
      {loading ? (
        <div className="relative w-12">
          <div className="spinner" />
        </div>
      ) : (
        children
      )}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
}
