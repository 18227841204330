import { isNil, mergeDeepRight, pathOr } from 'ramda'

const getInitialState = () => ({})

function requestStatesReducer(state = getInitialState(), action) {
  const { type, payload, meta } = action
  const actionTypeMatch = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)
  const isAsyncAction = !isNil(actionTypeMatch)

  if (!isAsyncAction) {
    return state
  }

  const { idKey } = pathOr({}, ['requestState'], meta)
  const [, requestActionType, requestState] = actionTypeMatch

  const stateKey = idKey ? `${requestActionType}_${payload[idKey]}` : requestActionType

  const isLoading = requestState === 'REQUEST'
  const errorMessage = requestState === 'FAILURE' ? payload.errorMessage : undefined

  return mergeDeepRight(state, {
    [stateKey]: { isLoading, errorMessage },
  })
}

export { requestStatesReducer }
