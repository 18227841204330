import { path } from 'ramda'
import { MODULE_NAMESPACE, LOADING_STATE_KEY } from './constants'

function extractBaseType(asyncActionType) {
  const { REQUEST } = asyncActionType
  const actionTypeMatch = /(.*)_(.*)/.exec(REQUEST)
  const [, baseType] = actionTypeMatch

  return baseType
}

function createLoadingStateSelector(asyncActionTypes) {
  return (state) => {
    return asyncActionTypes.some((asyncActionType) => {
      const baseType = extractBaseType(asyncActionType)
      return path([MODULE_NAMESPACE, baseType, LOADING_STATE_KEY], state)
    })
  }
}

function createUniqueLoadingStateSelector(asyncActionTypes) {
  return (state, id) => {
    return asyncActionTypes.some((asyncActionType) => {
      const baseType = extractBaseType(asyncActionType)
      const stateKey = `${baseType}_${id}`

      return path([MODULE_NAMESPACE, stateKey, LOADING_STATE_KEY], state)
    })
  }
}

export { createLoadingStateSelector, createUniqueLoadingStateSelector }
