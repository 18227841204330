import ky from 'ky'
import { API_URL } from 'consts'
import Crypto from 'crypto-js'

const Api = new (class Api {
  getJobDetails = async (referenceNo, uploadCode) => {
    const bearerToken = this.computeBearerToken(referenceNo, uploadCode)
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
    }
    const requestConfig = { headers }

    const getJobDetailsRequest = ky
      .get(`${API_URL}/upload-jobs/${referenceNo}`, requestConfig)
      .json()

    const getJobFilesRequest = ky
      .get(`${API_URL}/upload-jobs/${referenceNo}/documents`, requestConfig)
      .json()

    const [jobDetails, jobFiles] = await Promise.all([
      getJobDetailsRequest,
      getJobFilesRequest,
    ])

    const mergedJobDetails = {
      ...jobDetails,
      uploadedFiles: jobFiles.items,
    }

    return { jobDetails: mergedJobDetails, bearerToken }
  }

  submitJobForReview = async (referenceNo, uploadCode) => {
    const bearerToken = this.computeBearerToken(referenceNo, uploadCode)
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
    }
    const requestConfig = { headers }

    return ky.put(`${API_URL}/upload-jobs/${referenceNo}`, requestConfig)
  }

  getFileUploadPath = (referenceNo) => {
    return `${API_URL}/upload-jobs/${referenceNo}/documents`
  }

  computeBearerToken = (referenceNo, uploadCode) =>
    Crypto.HmacSHA256(referenceNo, uploadCode)
})()

export { Api }
