import { createAsyncActionTypes, createAsyncActionCreators } from 'utils/redux'

const MODULE_NAMESPACE = 'jobDetails'
const FETCH_JOB_DETAILS = createAsyncActionTypes(
  `${MODULE_NAMESPACE}/FETCH_JOB_DETAILS`
)
const SUBMIT_JOB_FOR_REVIEW = createAsyncActionTypes(
  `${MODULE_NAMESPACE}/SUBMIT_JOB_FOR_REVIEW`
)

const fetchJobDetails = createAsyncActionCreators(FETCH_JOB_DETAILS, [
  (referenceNo, uploadCode) => ({ referenceNo, uploadCode }),
  (jobDetails, bearerToken) => ({ jobDetails, bearerToken }),
  (errorMessage) => ({ errorMessage }),
])

const submitJobForReview = createAsyncActionCreators(SUBMIT_JOB_FOR_REVIEW, [
  (referenceNo, uploadCode) => ({ referenceNo, uploadCode }),
  undefined,
  (errorMessage) => ({ errorMessage }),
])

export { FETCH_JOB_DETAILS, SUBMIT_JOB_FOR_REVIEW, fetchJobDetails, submitJobForReview }
