import { useSelector } from 'react-redux'
import { getSubmittedForReview } from 'modules/jobDetails'
import { Link, Redirect } from 'react-router-dom'

function SubmittedPage() {
  const submittedForReview = useSelector(getSubmittedForReview)

  if (!submittedForReview) {
    return <Redirect from="/submitted" to="/" />
  }

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <img className="mb-8" style={{ height: 120, width: 200 }} src="/ghd-logo.jpg" />

      <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col">
        <h1 className="mb-4 text-gray-700 text-center">Job submitted for review!</h1>
        <p>All done! Thank you for using the GHD platform!</p>
        <Link href="/" to="/" className="text-center text-blue-700 mt-2">
          Start Again
        </Link>
      </div>
    </div>
  )
}

export { SubmittedPage }
