import { useSelector } from 'react-redux'
import { getIsAuthorized } from 'modules/auth'
import { Switch, Route, Redirect } from 'react-router-dom'
import { PrivateRoute } from 'components/PrivateRoute'
import { AccessPage } from 'components/AccessPage'
import { UploadPage } from 'components/UploadPage'
import { SubmittedPage } from 'components/SubmittedPage'

function App() {
  const isAuthorized = useSelector(getIsAuthorized)

  return (
    <div className="h-full bg-gray-100">
      <Switch>
        <PrivateRoute
          isLoggedIn={isAuthorized}
          loginPath="/"
          exact
          path="/upload"
          component={UploadPage}
        />

        <Route exact path="/" component={AccessPage} />
        <Route exact path="/submitted" component={SubmittedPage} />
        <Redirect from="/" to="/upload" />
      </Switch>
    </div>
  )
}

export { App }
