import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchJobDetails, getIsLoadingJobDetails } from 'modules/jobDetails'
import { isEmpty, trim, toUpper, pipe, map } from 'ramda'
import { Button } from '../common/Button'

const trimAndUpperCase = map(pipe(trim, toUpper))

function AccessPage() {
  const [referenceNo, setReferenceNo] = useState('')
  const [uploadCode, setUploadCode] = useState('')
  const dispatch = useDispatch()
  const isLoadingJobDetails = useSelector(getIsLoadingJobDetails)

  function dispatchFetchJobDetails() {
    if (isEmpty(referenceNo) || isEmpty(uploadCode)) return
    dispatch(fetchJobDetails.request(...trimAndUpperCase([referenceNo, uploadCode])))
  }

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <img className="max-w-xs p-6 pt-0" src="/ghd-logo.jpg" />

      <div className="max-w-sm bg-white rounded-lg shadow p-8 flex flex-col">
        <h1 className="mb-2 text-center text-gray-800">Log In</h1>
        <p className="mb-6 text-center text-sm text-gray-500 font-light">
          {
            "Enter your job's reference number and its unique upload code found on the GHD pilot app."
          }
        </p>

        {isLoadingJobDetails && (
          <div className="relative">
            <div className="spinner" />
          </div>
        )}

        {!isLoadingJobDetails && (
          <form className="flex flex-col">
            <label
              htmlFor="referenceNo"
              className="font-normal text-xs uppercase tracking-wider text-gray-500 mb-2"
            >
              Reference no
            </label>
            <input
              id="referenceNo"
              className="w-full h-10 px-4 mb-4 bg-gray-100 border-b-2 border-gray-300 rounded-lg text-base focus:shadow-outline"
              required
              name="referenceNo"
              type="text"
              value={referenceNo}
              onChange={(e) => setReferenceNo(e.target.value)}
            />

            <label
              htmlFor="uploadCode"
              className="font-normal text-xs uppercase tracking-wider text-gray-500 mb-2"
            >
              Upload code
            </label>
            <input
              id="uploadCode"
              className="w-full h-10 px-4 mb-6 bg-gray-100 border-b-2 border-gray-300 rounded-lg text-base focus:shadow-outline"
              required
              type="text"
              name="uploadCode"
              value={uploadCode}
              onChange={(e) => setUploadCode(e.target.value)}
            />

            <Button onClick={dispatchFetchJobDetails}>Submit</Button>
          </form>
        )}
      </div>

      <p className="mt-4 text-center text-gray-500 text-xs">
        {' '}
        © {new Date().getFullYear()} NearbySky. All rights reserved.{' '}
      </p>
    </div>
  )
}

export { AccessPage }
