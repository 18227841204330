import { createReducer } from 'utils/redux'
import { FETCH_JOB_DETAILS, SUBMIT_JOB_FOR_REVIEW } from './actions'

const getInitialState = () => ({
  entity: undefined,
  uploadCode: undefined,
  submittedForReview: false,
})

const jobDetailsReducer = createReducer(getInitialState(), {
  [FETCH_JOB_DETAILS.REQUEST]: (state, action) => ({
    ...state,
    uploadCode: action.payload.uploadCode,
  }),

  [FETCH_JOB_DETAILS.SUCCESS]: (state, { payload: { jobDetails } }) => ({
    ...state,
    submittedForReview: jobDetails.status === 'job_status_in_review',
    entity: jobDetails,
  }),

  [FETCH_JOB_DETAILS.FAILURE]: getInitialState,
  [SUBMIT_JOB_FOR_REVIEW.SUCCESS]: () => ({
    ...getInitialState(),
    submittedForReview: true,
  }),
})

export { jobDetailsReducer }
