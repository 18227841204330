// @flow

import { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

export class PrivateRoute extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    loginPath: PropTypes.string,
  }

  static defaultProps = {
    loginPath: '/view-job-details',
  }

  render() {
    const { isLoggedIn, loginPath, ...routeProps } = this.props

    if (!isLoggedIn) {
      return <Redirect to={loginPath} />
    }

    return <Route {...routeProps} />
  }
}
