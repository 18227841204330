import ReactDOM from 'react-dom'
import { App } from './App'
import { createBrowserHistory as createHistory } from 'history'
import { configureStore } from 'configureStore'

import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import 'style.css'

const history = createHistory()
const store = configureStore(history)
const rootElement = document.getElementById('root')

if (rootElement) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    rootElement
  )
}
