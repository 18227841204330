import { object, string } from 'yup'
import { isNil, path } from 'ramda'
import { createSelector as composeSelectors } from 'reselect'

function createAsyncActionTypes(baseType) {
  return {
    REQUEST: `${baseType}_REQUEST`,
    SUCCESS: `${baseType}_SUCCESS`,
    FAILURE: `${baseType}_FAILURE`,
  }
}

function createAction(type, payload, meta) {
  return { type, payload, meta }
}

function createActionCreator(type, payloadCreator = () => ({}), meta) {
  return (...args) => createAction(type, payloadCreator(...args), meta)
}

const asyncActionCreatorConfigSchema = object().shape({
  idKey: string(),
})

function createAsyncActionCreators(types, payloadCreators, meta) {
  const requestStateConfig = path(['requestIdKey'], meta)

  if (!isNil(requestStateConfig)) {
    asyncActionCreatorConfigSchema.validateSync(requestStateConfig)
  }

  const { REQUEST, SUCCESS, FAILURE } = types
  const [
    requestPayloadCreator,
    successPayloadCreator,
    failurePayloadCreator,
  ] = payloadCreators

  return {
    request: createActionCreator(REQUEST, requestPayloadCreator, meta),
    succeeded: createActionCreator(SUCCESS, successPayloadCreator, meta),
    failed: createActionCreator(FAILURE, failurePayloadCreator, meta),
  }
}

function createReducer(initialState, caseMap) {
  return (state = initialState, action) => {
    return Object.prototype.hasOwnProperty.call(caseMap, action.type)
      ? caseMap[action.type](state, action)
      : state
  }
}

export {
  createAsyncActionTypes,
  createActionCreator,
  createAsyncActionCreators,
  createReducer,
  composeSelectors,
}
