/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { memo, forwardRef } from 'react'
import { isEmpty, isNil, equals } from 'ramda'
import { useSelector } from 'react-redux'
import { getJobDetails } from 'modules/jobDetails'
import { Api } from 'api'
import ky from 'ky'

import { FilePond as FileUploader, registerPlugin } from 'react-filepond'
import FileUploaderFileTypeValidator from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import 'filepond/dist/filepond.min.css'
import { SHAPEFILE_CONTENT_TYPE, parseContentType } from './parseContentType'

registerPlugin(FileUploaderFileTypeValidator)
registerPlugin(FilePondPluginFileValidateSize)

// apparently the plugin config doesn't support number & GB unit
const S3_PUT_OBJECT_SIZE_LIMIT_IN_BYTES = '5000MB'

// TODO: should add e2e test to cover file upload logics since react-testing-lib doesn't support testing useRef yet
const UploadPanel = memo(
  forwardRef((_, ref) => {
    const { uploadCode, reference, uploadedFiles: files = [] } = useSelector(
      getJobDetails
    )

    const defaultHeaders = {
      authorization: `Bearer ${Api.computeBearerToken(reference, uploadCode)}`,
    }

    function uploadFile(
      _fieldName,
      file,
      _metadata,
      onUploaded,
      onError,
      onProgress,
      onAbort
    ) {
      const { name: fileName, size: fileSize, type: contentType } = file
      let req

      createSignedUrl(fileName).then(({ documentId, signedUrl }) => {
        if (isEmpty(signedUrl) || isNil(signedUrl)) {
          onError('File failed to upload. Try again in a little bit.')
        }

        req = prepareUploadRequest({
          signedUrl,
          contentType,
          onProgress: (e) => onProgress(e.lengthComputable, e.loaded, e.total),
          onResolved: () =>
            confirmFileUpload(req, documentId, fileSize, onUploaded, onError),
          onError,
        })

        req.send(file)
      })

      return {
        abort: () => {
          req.abort()
          onAbort()
        },
      }
    }

    async function createSignedUrl(fileName) {
      try {
        const response = await ky
          .post(Api.getFileUploadPath(reference), {
            json: { fileName },
            headers: {
              ...defaultHeaders,
              'content-type': 'application/json',
            },
          })
          .json()

        return response
      } catch (error) {
        return {}
      }
    }

    function prepareUploadRequest({ signedUrl, contentType, onProgress, onResolved }) {
      // We need to use XMLHttpRequest to allow us to track the upload progress.
      const req = new XMLHttpRequest()

      req.open('PUT', signedUrl, true)
      req.setRequestHeader('content-type', contentType)

      req.upload.onprogress = onProgress
      req.onload = onResolved

      return req
    }

    async function confirmFileUpload(req, documentId, fileSize, onUploaded, onError) {
      const fileUploadedSuccessfully = req.status >= 200 && req.status < 300
      if (!fileUploadedSuccessfully) {
        onError('File failed to upload. Try again in a little bit.')
        return
      }

      try {
        await ky
          .put(`${Api.getFileUploadPath(reference)}/${documentId}`, {
            json: { fileSize },
            headers: {
              ...defaultHeaders,
              'content-type': 'application/json',
            },
          })
          .json()
      } catch (error) {
        onError('File failed to upload. Try again in a little bit.')
        return
      }

      onUploaded(documentId)
    }

    function deleteFile(id, onDone, onError) {
      ky.delete(`${Api.getFileUploadPath(reference)}/${id}`, {
        headers: {
          ...defaultHeaders,
          'content-type': 'application/json',
        },
      })
        .then(() => onDone())
        .catch(() => onError())
    }

    return (
      <FileUploader
        id="file-uploader-input"
        ref={ref}
        files={files.map(({ id, fileName, fileSize, contentType, ...metadata }) => ({
          source: id,
          options: {
            type: 'local', // Is actually on the server.
            metadata,
            file: {
              name: fileName,
              type: contentType,
              size: fileSize,
            },
          },
        }))}
        allowMultiple
        maxFileSize={S3_PUT_OBJECT_SIZE_LIMIT_IN_BYTES}
        acceptedFileTypes={[
          // tiff + geotiff are included
          'image/*',
          'video/*',
          'application/pdf',

          // kml
          'application/vnd.google-earth.kml+xml',

          // catchall for all shapefile exts
          SHAPEFILE_CONTENT_TYPE,
        ]}
        // see https://pqina.nl/filepond/docs/api/plugins/file-validate-type/
        fileValidateTypeDetectType={(source, type) =>
          new Promise((resolve) => {
            resolve(parseContentType(source, type))
          })
        }
        server={{
          url: Api.getFileUploadPath(reference),
          headers: defaultHeaders,
          process: uploadFile,
          revert: deleteFile,
          remove: deleteFile,
        }}
      />
    )
  }),
  equals
)

export { UploadPanel }
